import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Alert } from 'react-bootstrap';
import CustomTextbox from '../controls/CustomTextBox';
import {
  PrimaryButton as Button,
  DefaultButton as CancelButton,
} from '../controls/Button';
import AccountService from '../../services/AccountService';
import AccountAction from '../../store/actions/AccountAction';

import { emailPattern } from '../../utils/Validations';
import { CommonAction } from '../../store/actions';
import { push } from 'connected-react-router';
const LoginInSection = ({ onCanceled, onSuccessed, onHide }) => {
  const [userEmailIsValid, setEmailIsValid] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const dispatch = useDispatch();
  const navigate = path => dispatch(push(path));
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = ({ email, password }) => {
    setPasswordIsValid(password ? true : false);
    setEmailIsValid(email ? true : false);
    if (!email || !password)
      return;
    AccountService.Login(email, password, result => {
      dispatch(AccountAction.login(result));
      dispatch(CommonAction.setCompany(result.Company));
      navigate("/");
    });
  };

  const onCanceledHandle = () => {
    if (onCanceled) {
      onCanceled();
    } else {
      if (onHide) onHide();
    }
  };

  const ononSuccessedHandle = () => {
    if (onSuccessed) {
      onSuccessed();
    } else {
      if (onHide) onHide();
    }
  };

  return (
    <div id="login">
      <CustomTextbox
        label="Email Address"
        //placeholder="Enter your email"
        type="text"
        {...register('email', { required: false, pattern: emailPattern })}
      />
      {!userEmailIsValid && (
        <Alert variant="danger">Email is required</Alert>
      )}
      {errors.email?.type === 'pattern' && (
        <Alert variant="danger">Email format is incorrect</Alert>
      )}
      <CustomTextbox
        label="Password"
        //placeholder="Enter your password"
        type="password"
        {...register('password', { required: false })}
      />
      {!passwordIsValid && <Alert variant="danger">Password is required</Alert>}
      <div className="tab-content-btns">
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          Login
        </Button>
      </div>
    </div>
  );
};

export default LoginInSection;
