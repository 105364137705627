import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { commonAction } from '../store/actions';
import ApiHelper from '../store/helpers/ApiHelper';
import { updateIntl } from 'react-intl-redux';
import MainLayout from '../components/layout/MainLayout';
import locale_en from '../localization/en.json';
import HeaderMenu from '../components/common/HeaderMenu';

const DashboardContainer = props => {
  const account = useSelector(state => state.account);

  const dispatch = useDispatch();
  const navigate = path => dispatch(push(path));
  useEffect(() => {  
    if (account?.CurrentUserSession != null) {
      navigate('/Orders');      
    }
    else{
      navigate('/Login');
    }
  }, [account]);
  return (
    <MainLayout>
      <HeaderMenu></HeaderMenu>
    </MainLayout>
  );
};

export default DashboardContainer;
