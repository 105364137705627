import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountAction } from '../../store/actions';
import CustomRadioButton from '../controls/RadioButton';
import './css/LanguageBasic.css';

const LanguageBasic = ({ onDone }) => {
  const dispatch = useDispatch();

  const account = useSelector(state => state.account);
  const language = account?.Language;
  const [btnDonetitle, setBtnDonetitle] = useState('Done');
  const [lang, setLang] = useState('en');

  useEffect(() => {
    setLang(language ? language : 'en');
  }, [account?.Language]);

  const onChangeInternal = (id, value) => {
    console.log('lng', id, value);
    setLang(value);
  };

  const onDoneHandle = () => {
    setBtnDonetitle('Changing...');
    setTimeout(() => {
      dispatch(AccountAction.setLanguage(lang));

      setBtnDonetitle('Changed');
      if (onDone) onDone();
    }, 1000);
  };

  return (
    <div className="language-basic-container">
      <CustomRadioButton
        id="langs-Turkish"
        name="langs"
        onChange={onChangeInternal}
        defaultChecked={language === 'tr'}
        label="Turkish"
        value="tr"
      ></CustomRadioButton>
      <CustomRadioButton
        id="langs-English"
        name="langs"
        onChange={onChangeInternal}
        defaultChecked={language ? language === 'en' : true}
        label="English"
        value="en"
      ></CustomRadioButton>
      <CustomRadioButton
        id="langs-German"
        name="langs"
        onChange={onChangeInternal}
        defaultChecked={language === 'du'}
        label="German"
        value="du"
      ></CustomRadioButton>
      <CustomRadioButton
        id="langs-Polish"
        name="langs"
        onChange={onChangeInternal}
        defaultChecked={language === 'pl'}
        label="Polish"
        value="pl"
      ></CustomRadioButton>
      <button onClick={() => onDoneHandle()} className="btn-done">
        {btnDonetitle}
      </button>
    </div>
  );
};

export default LanguageBasic;
