import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
//import AuthPopup from './../account/AuthPopup';
import { CommonAction } from '../../store/actions';

import './css/mainLayout.css';
import AuthContainer from '../../containers/AuthContainer';
import mediaWidths from '../../constants/mediaWidths';

import { useTranslation } from 'react-i18next';

const MainLayout = ({ children }) => {
  const [isAuthuticated, setIsAuthuticated] = useState(false);

  const { t, i18n } = useTranslation();

  const common = useSelector(state => state.common);
  const account = useSelector(state => state.account);

  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification');
  } else {
    Notification.requestPermission();
  }

  useEffect(() => {
    window.addEventListener('online', () =>
      dispatch(CommonAction.setIsOffline(false))
    );
    window.addEventListener('offline', () =>
      dispatch(CommonAction.setIsOffline(true))
    );
    return () => {
      window.removeEventListener(
        'online',
        dispatch(CommonAction.setIsOffline(false))
      );
      window.removeEventListener(
        'offline',
        dispatch(CommonAction.setIsOffline(true))
      );
    };
  }, []);

  useEffect(() => {
    if (account.CurrentUserSession != null) {
      setIsAuthuticated(true);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(account?.Language);
  }, [account?.Language]);

  const dispatch = useDispatch();
  const clearError = () => {
    dispatch(CommonAction.clearError());
  };

  const handleResize = () => {
    if (window.innerWidth <= mediaWidths.md.screen) {
      dispatch(CommonAction.setIsDisplayMobile(true));
      dispatch(CommonAction.setIsDisplayTablet(false));
    } else if (
      window.innerWidth > mediaWidths.md.screen &&
      window.innerWidth <= mediaWidths.lg.screen
    ) {
      dispatch(CommonAction.setIsDisplayTablet(true));
      dispatch(CommonAction.setIsDisplayMobile(false));
    } else {
      dispatch(CommonAction.setIsDisplayTablet(false));
      dispatch(CommonAction.setIsDisplayMobile(false));
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <React.Fragment>
      {common.IsLoading && (
        <div className="loader">
          <div className="loader-wheel"></div>
          <div className="loader-text"></div>
        </div>
      )}

      {isAuthuticated === false ? <AuthContainer></AuthContainer> : children}

      <Modal
        onHide={clearError}
        className="gobal-error-modal"
        backdrop="static"
        show={common.Error || common.GlobalError ? true : false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {common.Error && common.Error.title ? common.Error.title : 'Error'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {common.Error && (
            <p>
              {common.Error && common.Error.message
                ? common.Error.message
                : 'Something went wrong!'}
            </p>
          )}
          {common.GlobalError && (
            <p>
              {common.GlobalError && common.GlobalError.message
                ? common.GlobalError.message
                : 'Something went wrong!'}
            </p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={clearError} variant="primary">
            {'OK'}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <AuthPopup
        show={AuthPopupOpened}
        onHide={() => {
          setAuthPopupOpened(false);
        }}
        onShow={() => setAuthPopupOpened(true)}
      /> */}
    </React.Fragment>
  );
};

export default MainLayout;
