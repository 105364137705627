import moment from 'moment';

const ExportNumbersString = originTxt => {
  let numb = originTxt.match(/\d/g);
  return parseInt(numb.join(''));
};

const isNewNotificationSupported = () => {
  if (!window.Notification || !Notification.requestPermission) return false;
  if (Notification.permission === 'granted')
    console.log(
      'You must only call this *before* calling Notification.requestPermission(), otherwise this feature detect would bug the user with an actual notification!'
    );
  // try {
  //   new Notification('');
  // } catch (e) {
  //   if (e.name == 'TypeError') return false;
  // }
  return true;
};

const ExportValueOfArrayObject = ({
  list,
  key,
  searchParam,
  exportValueKey,
}) => {
  let findItem = list.filter(x => x[key] === searchParam);
  return exportValueKey ? findItem[0][exportValueKey] : findItem;
};

const generateId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const formatDateTime = (dateTime, formatString = 'DD/MM/YYYY hh:mm A') => {
  return moment(dateTime).format(formatString);
};

const formatDateTimeCalendar = dateTime => {
  return moment(dateTime).calendar();
};

const formatDateTimeFromNow = (
  dateTime,
  formatString = 'DD/MM/YYYY hh:mm A'
) => {
  return moment(dateTime).fromNow();
};

const groupByKey = (array, key) => {
  if (!array) return {};
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
};

const getStamp = () => {
  return Date.now();
};

export {
  ExportNumbersString,
  ExportValueOfArrayObject,
  generateId,
  formatDateTime,
  formatDateTimeFromNow,
  formatDateTimeCalendar,
  groupByKey,
  getStamp,
  isNewNotificationSupported,
};
