import React from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Modal } from 'react-bootstrap';

import './css/RejectOrder.css';
import CustomTextArea from '../controls/CustomTextArea';

const RejectOrderPopup = ({ onHide, show, onDone, id, showLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = value => {
    onDone(value.reason);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      className="rejectOrderPopup"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title>Tip Amount</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        {/* <h2>Reject Order #{id}</h2> */}
        <CustomTextArea
          name="reason"
          type="textarea"
          placeholder={'Enter your reject reason'}
          {...register('reason')}
        ></CustomTextArea>
        {errors.reason?.type === 'required' && (
          <Alert variant="danger">Please enter the reject reason</Alert>
        )}
        <div className="btn-group">
          <button onClick={onHide} className="btn-cancel">
            Cancel
          </button>
          <button onClick={handleSubmit(onSubmit)} className="btn-done">
            {showLoading ? `Rejecting...` : `Reject`}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RejectOrderPopup;
