import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import CardInfo from './CardInfo';
import './css/OrderList.css';

import OrderService from '../../services/OrderService';

import orderListEmptyIco from '../../contents/order/ic-order-list-empty.svg';

import orderListInProgressEmptyIco from '../../contents/order/ic-order-list-inprogress-empty.svg';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const OrderList = ({
  isDispalyMobile,
  isDispalyTablet,
  showLoading,
  orderList,
  selectedOrderId,
  onSelectedOrder,
  orderType,
  fetchMoreData,
  totalOrder,
  onUpdateState,
}) => {
  let emptyDes = '';
  let listHeight = 140;
  const common = useSelector(state => state.common);
  orderType = orderType.toLowerCase();

  switch (orderType) {
    case 'active':
      emptyDes = 'You do not have any pending orders';
      listHeight = orderList?.length > 1 ? 180 : 140;
      break;
    case 'scheduled':
      emptyDes = 'You do not have any scheduled orders';
      listHeight = orderList?.length > 1 ? 400 : 180;
      break;
    case 'completed':
      emptyDes = 'You do not have any completed orders';
      listHeight = orderList?.length > 1 ? 400 : 180;
      break;

    case 'inprogress':
      emptyDes = 'You have completed all your orders';
      listHeight = orderList?.length > 1 ? 400 : 180;
      break;

    default:
      break;
  }
  //Should get the Order list from the right endpoint

  const onSelectedOrderHandle = value => {
    onSelectedOrder({ orderType, value });
  };

  const onUpdateStateHandle = value => {
    OrderService.UpdateStatus(value.id, value.state, result => {
      onUpdateState({ id: value.id, orderType });
    });
  };

  return !showLoading ? (
    <>
      {orderList && orderList.length > 0 ? (
        <InfiniteScroll
          className="order-list-container"
          dataLength={orderList.length}
          next={val => {
            // console.log('next fetch data', val);
            fetchMoreData({
              selectedOrderType: orderType,
              fetchedRowsLength: window.innerHeight > 1300 ? 20 : 10,
              mainLoading: false,
              isItPaginationRequest: true,
            });
          }}
          hasMore={orderList.length < totalOrder}
          height={
            isDispalyMobile || isDispalyTablet
              ? listHeight
              : window.innerHeight - 190
          }
          loader={
            <div className="order-list-more-data-loading">
              <Spinner
                className="order-list-more-data-spinner"
                animation="border"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <br />
              <label>Is Loading Oldest Data...</label>
            </div>
          }
          endMessage={
            <div className="order-list-more-data-loading">
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all ({totalOrder})</b>
              </p>
            </div>
          }
          refreshFunction={() => {
            fetchMoreData({
              selectedOrderType: orderType,
              fetchedRowsLength: window.innerHeight > 1300 ? 20 : 10,
              mainLoading: true,
              isItPaginationRequest: false,
              pageIndex: 1,
            });
          }}
          pullDownToRefresh
          pullDownToRefreshThreshold={10}
          pullDownToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>
              &#8595; Pull down to refresh
            </h3>
          }
          releaseToRefreshContent={
            <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          }
        >
          {orderList.map((order, index) => (
            <CardInfo
              key={order.ID}
              selected={
                isDispalyMobile || isDispalyTablet
                  ? ''
                  : order.ID === selectedOrderId
              }
              completed={orderType === 'completed'}
              onSelected={onSelectedOrderHandle}
              inProgress={orderType === 'inprogress'}              
              order={order}
              onUpdateState={onUpdateStateHandle}
            ></CardInfo>
          ))}
        </InfiniteScroll>
      ) : (
        <div className="order-list-container">
          <div
            className={
              orderType === 'inprogress'
                ? `empty-list inprogressempty`
                : `empty-list`
            }
          >
            <img
              src={
                orderType === 'inprogress'
                  ? orderListInProgressEmptyIco
                  : orderListEmptyIco
              }
              alt=""
            ></img>
            <p>{emptyDes}</p>
          </div>
        </div>
      )}
    </>
  ) : (
    <div className="order-list-container ">
      <div className="order-list-loading">
        <Spinner
          className="order-list-loading-spinner"
          animation="border"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <br />
        <label>Loading...</label>
      </div>
    </div>
  );
};

export default OrderList;
