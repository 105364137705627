import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import addressTypes from '../../constants/addressTypes';
import orderMethods from '../../constants/orderMethods';
import {
  ExportValueOfArrayObject,
  formatDateTime,
  formatDateTimeCalendar,
} from '../../utils';
import './css/CardDetail.css';
import DeliveryTimeContainer from './DeliveryTimeContainer';

import ConfrimAlertMessage from '../controls/ConfrimAlertMessage';

import OrderService from '../../services/OrderService';

import { CommonAction } from '../../store/actions';

import orderDetailEmptyIco from '../../contents/order/ic-order-detail-empty.svg';
import RejectOrderPopup from './RejectOrderPopup';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import orderStatus from '../../constants/orderStatus';
import storeType from '../../constants/storeType';

const CardDetail = ({
  isDispalyMobile,
  isDispalyTablet,
  showLoading,
  order,
  onReloadList,
}) => {
  const dispatch = useDispatch();
  const [confirmAlertPopupOpened, setConfirmAlertPopupOpened] = useState(false);
  const [rejectOrderPopupOpened, setRejectOrderPopupOpened] = useState(false);
  const [isParentAmountPopoupOpened, setIsParentAmountPopoupOpened] =
    useState(false);
  const [deliveryTime, setDeliverytime] = useState(0);
  const [showRejectLoading, setShowRejectLoading] = useState(false);
  const [showRejected, setShowRejected] = useState(false);
  const [showAcceptLoading, setShowAcceptLoading] = useState(false);
  const [showAccepted, setShowAccepted] = useState(false);

  const common = useSelector(state => state.common);
  const company = common.Company;
  const { t } = useTranslation();

  const printOrder=(orderID)=>{
    OrderService.GetOrderPrinting(orderID, result => {
      var contents = result.Data;
      var printingIFrame = document.createElement('iframe');
      printingIFrame.name = "orderPrintingIFrame";
      printingIFrame.style.position = "absolute";
      printingIFrame.style.top = "-1000000px";
      document.body.appendChild(printingIFrame);
      var frameDoc = printingIFrame.contentWindow ? printingIFrame.contentWindow : printingIFrame.contentDocument.document ? printingIFrame.contentDocument.document : printingIFrame.contentDocument;
      frameDoc.document.open();
      frameDoc.document.write('<html><head><title>Order Receipt</title>');
      frameDoc.document.write('</head><body>');
      frameDoc.document.write(contents);
      frameDoc.document.write('</body></html>');
      frameDoc.document.close();
      setTimeout(function () {
          window.frames["orderPrintingIFrame"].focus();        
          window.frames["orderPrintingIFrame"].print();
          document.body.removeChild(printingIFrame);
      }, 500);
      return false;
    });
    
 
 }
  const onSetDeliveryTimeHandle = value => {
    setDeliverytime(value);
  };

  const rejectTheOrder = reason => {
    const id = order.ID;
    setShowRejectLoading(true);
    OrderService.RejectOrder({ OrderID: id, Reason: reason }, result => {
      setRejectOrderPopupOpened(false);
      setShowRejectLoading(false);
      setShowRejected(true);
      onReloadList();
      if (isDispalyMobile || isDispalyTablet) {
        dispatch(CommonAction.setBackNav(''));
      }
    });
  };

  const acceptAndConfirmOrder = () => {
    const id = order.ID;
    const eta = parseInt(deliveryTime);
    setShowAcceptLoading(true);
    OrderService.AcceptOrder({ OrderID: id, Eta: eta }, result => {
      setConfirmAlertPopupOpened(false);
      setShowAcceptLoading(false);
      setShowAccepted(true);
      onReloadList();
      if (isDispalyMobile || isDispalyTablet) {
        dispatch(CommonAction.setBackNav(''));
      }
    });
  };

  return (
    <div key={order?.ID} className="card-detail-container">
      {order ? (
        showLoading ? (
          <div className="card-loading">
            <Spinner
              className="card-loading-spinner"
              animation="border"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <br />
            <label>Loading...</label>
          </div>
        ) : (
          <>
            {' '}
            <div className="top">
              <div className="left">
                {(order.Status == orderStatus.Pending && order.StoreType == storeType.Pos) ? 'Payment Info' :
                order?.OrderMethod &&
                  `${ExportValueOfArrayObject({
                    list: orderMethods,
                    key: 'id',
                    searchParam: order?.OrderMethod,
                    exportValueKey: 'title',
                  })} Order Info`}
              </div>
              <div className="right">
              <button className='btn-print' onClick={()=>printOrder(order?.ID)}>Print</button>
                {(order?.Status == orderStatus.Pending && order?.StoreType != storeType.Pos) && (
                  <button
                    onClick={() => {
                      setRejectOrderPopupOpened(true);
                    }}
                  >
                    {showRejectLoading
                      ? `Rejecting...`
                      : showRejected
                      ? 'Rejected'
                      : 'Reject'}
                  </button>
                )}
              </div>
            </div>
            {(order?.ID && order?.Status == orderStatus.Pending && order?.StoreType != storeType.Pos) && (
              <div key={order.ID} className="fixed">
                <DeliveryTimeContainer
                  onSetTime={onSetDeliveryTimeHandle}
                  onSetTimeAmountPopoupOpened={setIsParentAmountPopoupOpened}
                  parentTimeAmountPopoupOpened={isParentAmountPopoupOpened}
                  selectedDeliveryTime={deliveryTime}
                ></DeliveryTimeContainer>

                <div className="btn-section">
                  {/* {deliveryTime !== 0 && (
                    <label>
                      {deliveryTime === 0 ? `` : `${deliveryTime} min`}
                    </label>
                  )} */}
                  <button
                    onClick={() => acceptAndConfirmOrder()}
                    className="order-delivery-confirm"
                  >
                    {showAcceptLoading
                      ? `Accepting...`
                      : showAccepted
                      ? 'Accepted'
                      : deliveryTime === 0
                      ? `Accept`
                      : `Confirm ${deliveryTime} min delivery time`}
                  </button>
                </div>
              </div>
            )}
            <div
              className={
                order?.Status === 1 ? 'scrollable ' : 'scrollable-alone'
              }
              // style={{
              //   height:
              //     order?.Status === 1
              //       ? window.innerWidth > 1300
              //         ? window.innerHeight > 780
              //           ? 630 - 50
              //           : 430
              //         : 430
              //       : window.innerHeight - 180,
              // }}
            >
              <div className="middle">
                <p className="title">
                  <span>#{order?.ID}</span>
                  {order?.ExternalID && <span><span style={{fontWeight:400}}>POS Id : </span>#{order?.ExternalID}</span>}
                </p>
                
                <p className="warning">
                  {order?.OrderDate
                    ? `Scheduled Order on ${formatDateTime(
                        order?.OrderDate,
                        'llll'
                      )}`
                    : 'ASAP'}
                </p>
                <p>
                  {order?.CreationDate
                    ? `Order placed on ${formatDateTime(
                        order?.CreationDate,
                        'llll'
                      )}`
                    : ''}
                </p>
                <p>{order?.PaymentMethod?.Name}</p>
              </div>
              <div className="middle">
                <p className="title">{order?.Customer?.FullName}</p>
                <p>{order?.Phone}</p>
                <p>{order?.Customer?.Email}</p>
                <p></p>
                <p>
                  {order?.CustomerAddress &&
                    `${ExportValueOfArrayObject({
                      list: addressTypes,
                      key: 'value',
                      searchParam: order?.CustomerAddress?.AddressType,
                      exportValueKey: 'title',
                    })} :`}
                  <br />
                  {order?.CustomerAddress?.Street1}{' '}
                  {order?.CustomerAddress?.Street2}{' '}
                  {order?.CustomerAddress?.Street3}
                  {order?.CustomerAddress?.DistrictName}{' '}
                  {order?.CustomerAddress?.CityTownName}{' '}
                  {order?.CustomerAddress?.StateProvinceName}{' '}
                  {order?.CustomerAddress?.PostalCode}{' '}
                  {order?.CustomerAddress?.BuildingNo
                    ? `${t('BUILDING_NO')}:${order?.CustomerAddress?.BuildingNo} `
                    : ``}{' '}
                  {order?.CustomerAddress?.Floor
                    ? `${t('FLOOR')}:${order?.CustomerAddress?.Floor} `
                    : ``}{' '}
                  {order?.CustomerAddress?.ApartmentNo
                    ? `${t('APARTMENT_NO')}:${order?.CustomerAddress?.ApartmentNo} `
                    : ``}{' '}
                  {order?.CustomerAddress?.Phone}

                  {order.OrderMethod==5 && order.Room && (<><p className="title">Room</p> <p>{order.Room.Name}</p></>)}
                </p>
              </div>
              <div className="bottom">
                {order?.Note && order?.Note !== ' ' && (
                  <p className="note">{order?.Note}</p>
                )}
              </div>
              <div
                className={`items-container ${
                  order?.Items?.length > 0 && 'filled'
                }`}
              >
                {order?.Items?.map(cartItem => {
                  return (
                    <div key={cartItem.ID} className="cartitem">
                      <div className="quantity-container">
                        {cartItem.Quantity}x
                      </div>
                      <div className="name">
                        {cartItem.Name}
                        <div className="options">
                          {cartItem.Options &&
                            cartItem.Options.length > 0 &&
                            cartItem.Options.map((option, index) => {
                              return (
                                <div className="option-name">
                                  {option.Name} (                                  
                                  {option.Values.map((value, index) => {
                                    return <span key={index}>{value.Name},</span>;
                                  })}
                                  )
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <span className="price">
                        <FormattedNumber
                          value={cartItem.Total}
                          style="currency"
                          currency={company.Currency.Code}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="summary">
                <div className="summary-row">
                  <span>Subtotal</span>
                  <span>
                    <FormattedNumber
                      value={order?.SubTotal}
                      style="currency"
                      currency={company.Currency.Code}
                    />
                  </span>
                </div>
                {order?.DeliveryFee > 0 && (
                  <div className="summary-row">
                    <span>Delivery Fee</span>
                    <span>
                      <FormattedNumber
                        value={order?.DeliveryFee}
                        style="currency"
                        currency={company.Currency.Code}
                      />
                    </span>
                  </div>
                )}
                {order?.CardSurcharge > 0 && (
                  <div className="summary-row">
                    <span>Card Surcharge</span>
                    <span>
                      <FormattedNumber
                        value={order?.CardSurcharge}
                        style="currency"
                        currency={company.Currency.Code}
                      />
                    </span>
                  </div>
                )}
                {order?.ServiceCharge > 0 && (
                  <div className="summary-row">
                    <span>Service Charge</span>
                    <span>
                      <FormattedNumber
                        value={order?.ServiceCharge}
                        style="currency"
                        currency={company.Currency.Code}
                      />
                    </span>
                  </div>
                )}
                {order?.Tax && order?.Tax?.IsIncluded === true && (
                  <div className="summary-row">
                    <span>Tax</span>
                    <span>
                      <FormattedNumber
                        value={order?.Tax?.Tax}
                        style="currency"
                        currency={company.Currency.Code}
                      />
                    </span>
                  </div>
                )}
                {order?.Tip > 0 && (
                  <div className="summary-row">
                    <span>Tip</span>
                    <span>
                      <FormattedNumber
                        value={order?.Tip}
                        style="currency"
                        currency={company.Currency.Code}
                      />
                    </span>
                  </div>
                )}
                {order?.Discount > 0 && (
                  <div className="summary-row">
                    <span>Discount</span>
                    <span>
                      <FormattedNumber
                        value={order?.Discount}
                        style="currency"
                        currency={company.Currency.Code}
                      />
                    </span>
                  </div>
                )}
                {order?.UsedPoints > 0 && (
                  <div className="summary-row">
                    <span>Used Points</span>
                    <span>
                      <FormattedNumber
                        value={order?.UsedPoints}
                        style="currency"
                        currency={company.Currency.Code}
                      />
                    </span>
                  </div>
                )}
              </div>
              <div className="summary summary-total">
                <div className="summary-row total">
                  <span>Total</span>
                  <span>
                    <FormattedNumber
                      value={order?.Total}
                      style="currency"
                      currency={company.Currency.Code}
                    />
                  </span>
                </div>
              </div>             
            </div>
            {rejectOrderPopupOpened && (
              <RejectOrderPopup
                onHide={() => {
                  setRejectOrderPopupOpened(false);
                }}
                show={rejectOrderPopupOpened}
                onDone={rejectTheOrder}
                id={order?.ID}
                showLoading={showRejectLoading}
              ></RejectOrderPopup>
            )}
            {confirmAlertPopupOpened && (
              <ConfrimAlertMessage
                show={confirmAlertPopupOpened}
                yesTitle="Yes"
                noTitle="Cancel"
                title="Accept order confirmation"
                message="Are you sure?"
                size="sm"
                onHide={() => {
                  setConfirmAlertPopupOpened(false);
                }}
                noClickedfn={() => {
                  setConfirmAlertPopupOpened(false);
                }}
                yesClickedfn={() => {
                  acceptAndConfirmOrder();
                }}
              />
            )}
          </>
        )
      ) : (
        <div className="empty-list">
          <img src={orderDetailEmptyIco} alt=""></img>
          <p>Select an order to see order details</p>
        </div>
      )}
    </div>
  );
};

export default CardDetail;
