import React, { useEffect, useRef } from 'react';
import { Nav } from 'react-bootstrap';
import DeliveryTimePopup from './DeliveryTimePopup';
import ScrollContainer from 'react-indiana-drag-scroll';

const DeliveryTimeContainer = ({
  onSetTime,
  onSetTimeAmountPopoupOpened,
  parentTimeAmountPopoupOpened,
  selectedDeliveryTime,
}) => {
  const scroll_container = useRef(null);

  useEffect(() => {
    onSetTime(0);
    var scrollableContent = scroll_container.current.getElement();
    scrollableContent.addEventListener('wheel', e => {
      if (e.deltaY > 0) scrollableContent.scrollLeft += 100;
      else scrollableContent.scrollLeft -= 100;
    });

    return () => scrollableContent.removeEventListener('wheel', e => {});
  }, []);

  const setKeyHandle = value => {
    onSetTimeAmountPopoupOpened(value === 'ozal');

    if (value !== 'ozal') {
      onSetTime(parseInt(value));
    }
  };

  const times = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

  return (
    <div className="delivery-time">
      {parentTimeAmountPopoupOpened && (
        <DeliveryTimePopup
          onHide={() => {
            onSetTimeAmountPopoupOpened(false);
          }}
          show={parentTimeAmountPopoupOpened}
          onDone={setKeyHandle}
          time={selectedDeliveryTime}
        ></DeliveryTimePopup>
      )}
      <div className="tabs">
        <Nav
          // id="scroll_h_container"
          variant="pills"
          onSelect={k => setKeyHandle(k)}
        >
          <ScrollContainer
            className="scroll_container"
            ref={scroll_container}
          >
            {times.map((time, index) => (
              <Nav.Item key={index}>
                <Nav.Link eventKey={time}>{time} mins</Nav.Link>
              </Nav.Item>
            ))}
            <Nav.Item key="ozal">
              <Nav.Link eventKey="ozal">Other Time</Nav.Link>
            </Nav.Item>
          </ScrollContainer>
        </Nav>
      </div>
    </div>
  );
};

export default DeliveryTimeContainer;
