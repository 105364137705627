import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountAction } from '../../store/actions';
import CustomCheckBox from '../controls/CustomCheckBox';
import './css/SettingBasic.css';

const SettingBasic = ({ onDone }) => {
  const dispatch = useDispatch();

  const account = useSelector(state => state.account);
  const setting = account?.Setting;
  const [btnDonetitle, setBtnDonetitle] = useState('Done');
  const [checkedMuteAlert, setCheckedMuteAlert] = useState(false);
  const [checkedHideAlertPopup, setCheckedHideAlertPopup] = useState(false);
  const [checkedAutoAcceptOrders, setCheckedAutoAcceptOrders] = useState(false);
  const [deliveryDuration, setDeliveryDuration] = useState(null);
  const [pickupDuration, setPickupDuration] = useState(null);
  const [eatInDuration, setEatInDuration] = useState(null);
  const [tableDuration, setTableDuration] = useState(null);
  const [roomServiceDuration, setRoomServiceDuration] = useState(null);

  // Setting: {
  //   MuteAlert: false,
  //   HideAlertPopup: false,
  //   AutoAcceptOrders: false,
  //   DeliveryDefaultMin: null,
  //   PickupDefaultMin: null,
  //   EatDefaultMin: null,
  //   TableOrderDefaultMin: null,
  // },

  useEffect(() => {
    setCheckedMuteAlert(setting?.MuteAlert);
    setCheckedHideAlertPopup(setting?.HideAlertPopup);
    setCheckedAutoAcceptOrders(setting?.AutoAcceptOrders);
    setDeliveryDuration(setting?.DeliveryDefaultMin);
    setPickupDuration(setting?.PickupDefaultMin);
    setEatInDuration(setting?.EatDefaultMin);
    setTableDuration(setting?.TableOrderDefaultMin);
    setRoomServiceDuration(setting?.RoomServiceDefaultMin);
  }, [account?.Setting]);

  const onChangeInternal = (id, checked) => {
    if (id === 'alertMute') setCheckedMuteAlert(checked);
    if (id === 'hideAlertPopup') setCheckedHideAlertPopup(checked);
    if (id === 'autoAcceptOrders') setCheckedAutoAcceptOrders(checked);
  };

  const onDoneHandle = () => {
    setBtnDonetitle('Saving...');
    setTimeout(() => {
      dispatch(
        AccountAction.setSetting({
          MuteAlert: checkedMuteAlert,
          HideAlertPopup: checkedHideAlertPopup,
          AutoAcceptOrders: checkedAutoAcceptOrders,
          DeliveryDefaultMin: deliveryDuration
            ? parseInt(deliveryDuration)
            : null,
          PickupDefaultMin: pickupDuration ? parseInt(pickupDuration) : null,
          EatDefaultMin: eatInDuration ? parseInt(eatInDuration) : null,
          TableOrderDefaultMin: tableDuration ? parseInt(tableDuration) : null,
          RoomServiceDefaultMin:roomServiceDuration?parseInt(roomServiceDuration) : null,
        })
      );

      setBtnDonetitle('Saved');
      if (onDone) onDone();
    }, 1500);
  };

  return (
    <div className="setting-basic-container">
      <CustomCheckBox
        id="alertMute"
        key="alertMute"
        defaultChecked={setting?.MuteAlert}
        onChange={onChangeInternal}
        name="alertMute"
        label="Mute alert"
      ></CustomCheckBox>

      <CustomCheckBox
        id="hideAlertPopup"
        key="hideAlertPopup"
        defaultChecked={setting?.HideAlertPopup}
        onChange={onChangeInternal}
        name="hideAlertPopup"
        label="Hide alert popup"
      ></CustomCheckBox>

      <CustomCheckBox
        id="autoAcceptOrders"
        key="autoAcceptOrders"
        defaultChecked={setting?.AutoAcceptOrders}
        onChange={onChangeInternal}
        name="autoAcceptOrders"
        label="Auto Accept Orders"
      ></CustomCheckBox>

      <div className="textbox-section">
        <div className="textbox-item">
          <label>Delivery</label>
          <div className="textbox-section-container">
            <input
              type="number"
              name="deliveryDuration"
              onChange={e => setDeliveryDuration(e.target.value)}
              value={deliveryDuration}
              placeholder="Duration (min)"
            ></input>
          </div>
        </div>
        <div className="textbox-item">
          <label>Pickup</label>
          <div className="textbox-section-container">
            <input
              type="number"
              name="pickupDuration"
              onChange={e => setPickupDuration(e.target.value)}
              value={pickupDuration}
              placeholder="Duration (min)"
            ></input>
          </div>
        </div>
      </div>
      <div className="textbox-section">
        <div className="textbox-item">
          <label>Eat In</label>
          <div className="textbox-section-container">
            <input
              type="number"
              name="eatInDuration"
              onChange={e => setEatInDuration(e.target.value)}
              value={eatInDuration}
              placeholder="Duration (min)"
            ></input>
          </div>
        </div>
        <div className="textbox-item">
          <label>Table Order</label>
          <div className="textbox-section-container">
            <input
              type="number"
              name="tableDuration"
              onChange={e => setTableDuration(e.target.value)}
              value={tableDuration}
              placeholder="Duration (min)"
            ></input>
          </div>
        </div>
      </div>
      <button onClick={() => onDoneHandle()} className="btn-done">
        {btnDonetitle}
      </button>
    </div>
  );
};

export default SettingBasic;
