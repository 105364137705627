import { AccountTypes } from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    CurrentUserSession: null,
    Setting: {
      MuteAlert: false,
      HideAlertPopup: false,
      AutoAcceptOrders: false,
      DeliveryDefaultMin: null,
      PickupDefaultMin: null,
      EatDefaultMin: null,
      TableOrderDefaultMin: null,
      RoomServiceDefaultMin:null
    },
    Language: 'en',
  },
  action
) => {
  switch (action.type) {
    case AccountTypes.Login:
      return {
        ...state,
        CurrentUserSession: action.payload,
      };
    case AccountTypes.Logout:
      return {
        ...state,
        CurrentUserSession: null,
      };
    case AccountTypes.SetSetting:
      return {
        ...state,
        Setting: action.payload,
      };
    case AccountTypes.SetLang:
      return {
        ...state,
        Language: action.payload,
      };
    default:
      return state;
  }
};
