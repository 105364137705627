import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLocaleFile from './en.json';
import duLocaleFile from './du.json';
import trLocaleFile from './tr.json';
import plLocaleFile from './pl.json';

// the translations
const resources = {
  en: {
    translation: enLocaleFile,
  },
  du: {
    translation: duLocaleFile,
  },
  tr: {
    translation: trLocaleFile,
  },
  pl: {
    translation: plLocaleFile,
  },
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
  });

export default i18n;
