import deliveryIcon from '../contents/ordermethod/delivery.svg';
import pickupIcon from '../contents/ordermethod/takeaway.svg';

export default [
  {
    id: 1,
    title: 'Delivery',
    description: 'I want to order for delivery',
    icon: deliveryIcon,
  },
  {
    id: 2,
    title: 'Pickup',
    description: 'I want to collect my order',
    icon: pickupIcon,
  },
  {
    id: 3,
    title: 'EatIn',
    description: 'I want to order for eat-in',
    icon: deliveryIcon,
  },
  {
    id: 4,
    title: 'TableOrder',
    description: 'I want to order to my table',
    icon: deliveryIcon,
  },
  {
    id: 5,
    title: 'RoomService',
    description: 'I want to order to my room',
    icon: deliveryIcon,
  },
  {
    id: 6,
    title: 'SeatService',
    description: 'I want to order to my room',
    icon: deliveryIcon,
  },
];
