import React, { useState } from 'react';
import './css/textarea.css';
import eyeDisableIco from '../../contents/common/ic-password-eye-disable.svg';
import eyeIco from '../../contents/common/ic-password-eye.svg';

const CustomTextArea = ({
  label,
  name,
  type,
  placeholder,
  icon,
  onChange,
  defaultValue = '',
  autoComplete,
  readOnly = false,
  disabled = false,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
      {label && <label>{label}</label>}
      <div className="textarea-container">
        {icon && (
          <div className="left-icon">
            <img src={icon} alt="location" />
          </div>
        )}
        {autoComplete ? (
          <textarea
            readOnly={readOnly}
            disabled={disabled}
            autoComplete={autoComplete}
            name={name}
            type={
              type === 'password' ? (passwordShown ? 'text' : 'password') : type
            }
            placeholder={placeholder}
            onChange={onChange}
            defaultValue={defaultValue}
          />
        ) : (
          <textarea
            readOnly={readOnly}
            disabled={disabled}
            name={name}
            type={
              type === 'password' ? (passwordShown ? 'text' : 'password') : type
            }
            placeholder={placeholder}
            onChange={onChange}
            defaultValue={defaultValue}
          />
        )}
        {type === 'password' && (
          <img
            onClick={togglePasswordVisiblity}
            src={passwordShown ? eyeDisableIco : eyeIco}
          ></img>
        )}
      </div>
    </>
  );
};

export default CustomTextArea;
