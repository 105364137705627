import orderMethods from '../../constants/orderMethods';
import storeType from '../../constants/storeType';
import orderStatus from '../../constants/orderStatus';
import orderState from '../../constants/orderState';
import {
  ExportValueOfArrayObject,
  formatDateTime,
  formatDateTimeFromNow,
} from '../../utils';
import './css/CardInfo.css';

const CardInfo = ({
  order,
  onSelected,
  selected = false,
  inProgress = false,
  completed = false,
  onUpdateState
}) => {
  //   console.log('CardInfo order', order);

  const onInProgressOrderReadyHandle = e => {
    e.stopPropagation();
    let id = order.ID;
    let updateToNewState;
    // Delivery mode
    if (order.OrderMethod === 1) {
      updateToNewState = orderState.InDelivery;
      //InDelivery = 80
    }
    // Pickup mode
    else if (order.OrderMethod === 2) {
      updateToNewState = orderState.ReadyForPickup;
      //ReadyForPickup = 70,
    }
    else{
      updateToNewState = orderState.Prepared;
    }
    onUpdateState({ id: order.ID, state: updateToNewState });
  };

  const onSelectedHandle = value => {
    onSelected(value);
  };

  return (
    <div
      onClick={() => (selected ? `` : onSelectedHandle(order.ID))}
      className={
        selected
          ? inProgress
            ? `card-info-container in-progress selected`
            : `card-info-container selected ${completed ? 'in-progress' : ''}`
          : inProgress
          ? `card-info-container in-progress`
          : `card-info-container ${(order.Status == orderStatus.Pending && order.StoreType == storeType.Pos) ? 'store-type-pos ':''} ${completed ? 'in-progress' : ''}`
      }
    >
      <div className="top">
        <div className="left">
          <b>
            {(order.Status == orderStatus.Pending && order.StoreType == storeType.Pos) ? 'Payment' :
            order?.OrderMethod &&
              ExportValueOfArrayObject({
                list: orderMethods,
                key: 'id',
                searchParam: order?.OrderMethod,
                exportValueKey: 'title',
              })}
          </b>
          {inProgress ? (
            <>
              <br /> {order?.CustomerFullName} 
            </>
          ) : (
            ``
          )}
        </div>
        <div className="right">
          {inProgress && (order?.OrderState==orderState.Created || order?.OrderState==orderState.ReceivedByPos) ? (
            <button onClick={e => onInProgressOrderReadyHandle(e)}>
              {order?.OrderMethod === 1
                ? `Ready to delivery`
                : order?.OrderMethod === 2
                ? `Ready to pickup`
                : `Order Ready`}
            </button>
          ) : completed ? (
            ``
          ) : (
            `New`
          )}
        </div>
      </div>
      {!inProgress && (
        <div className="middle">
          <div className="left">{order?.CustomerFullName}</div>
          <div className="right"></div>
        </div>
      )}
      <div className={(order.Status == orderStatus.Pending && order.StoreType == storeType.Pos) ? 'bottom store-type-pos':'bottom'}>
        <div className="left">
          {order?.OrderDate ? (
            <b>{formatDateTime(order?.OrderDate)}</b>
          ) : (
            'ASAP'
          )}
        </div>
        <div className="right">
          Placed on{' '}
          {order?.CreationDate
            ? formatDateTimeFromNow(order?.CreationDate)
            : ''}
        </div>
      </div>
    </div>
  );
};

export default CardInfo;
