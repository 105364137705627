import { Modal } from 'react-bootstrap';
import './css/SettingPopup.css';
import SettingBasic from './SettingBasic';

const SettingPopup = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="setting-basic-box"
      size="sm"
      centered
    >
      <Modal.Header closeButton>
        <div>Settings</div>
      </Modal.Header>
      <Modal.Body>
        <SettingBasic onDone={onHide}></SettingBasic>
      </Modal.Body>
    </Modal>
  );
};

export default SettingPopup;
