import './css/HeaderMenu.css';

import menuIco from '../../contents/common/ic-menu.svg';
import logoWhiteIco from '../../contents/common/logo-white.svg';
import refreshIco from '../../contents/common/ic-refresh.svg';
import backIco from '../../contents/common/ic-arrow-back.svg';
import bellIco from '../../contents/common/ic-bell.svg';
import { useEffect, useState } from 'react';
import SideBarMenu from './SideBarMenu';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { CommonAction } from '../../store/actions';
import { useTranslation } from 'react-i18next';

const HeaderMenu = () => {
  const [showSiderBarMenu, setShowSiderBarMenu] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = path => dispatch(push(path));

  const common = useSelector(state => state.common);
  const order = useSelector(state => state.order);

  useEffect(() => {
    dispatch(CommonAction.setBackNav(''));
  }, []);

  const onBackNavHandle = () => {
    // const navPath = common.BackNav;
    dispatch(CommonAction.setBackNav(''));
    // navigate(navPath);
  };

  const onShowMenuHandle = () => setShowSiderBarMenu(true);
  return (
    <>
      <div
        className={
          common?.IsOffline === true
            ? `header-menu-container header-menu-container-offline`
            : 'header-menu-container'
        }
      >
        <button
          className="left-section"
          onClick={common?.BackNav ? onBackNavHandle : onShowMenuHandle}
        >
          <img src={common?.BackNav ? backIco : menuIco} alt=""></img>
        </button>
        <div className="center-section">
          {common?.IsOffline === true ? (
            <label>Offline! Please check your internet connection.</label>
          ) : order && order.ActiveTotalCount && order.ActiveTotalCount > 0 ? (
            <>
              <img src={bellIco} alt=""></img>
              <label>
                {t('header-notification-title', {
                  activeTotalCount: order.ActiveTotalCount,
                })}
              </label>
            </>
          ) : (
            <img src={logoWhiteIco} alt=""></img>
          )}
        </div>
        {/* <button className="right-section">
          <img src={refreshIco} alt=""></img>
        </button> */}
      </div>

      {showSiderBarMenu && (
        <SideBarMenu
          setShow={setShowSiderBarMenu}
          show={showSiderBarMenu}
        ></SideBarMenu>
      )}
    </>
  );
};

export default HeaderMenu;
