import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReportWebVitals from './ReportWebVitals';
import storeManager from './store';
import DashboardContainer from './containers/DashboardContainer';

import './localization/i18n';

import 'bootstrap/dist/css/bootstrap.min.css';
import './containers/css/Index.css';
import OrdersContainer from './containers/OrdersContainer';
import SettingContainer from './containers/SettingContainer';
import DailyReportContainer from './containers/DailyReportContainer';
import LanaguagesContainer from './containers/LanaguagesContainer';

import * as serviceWorker from './RegisterServiceWorker';
import AuthContainer from './containers/AuthContainer';

ReactDOM.render(
  <Provider store={storeManager.createStore()}>
    <PersistGate loading={null} persistor={storeManager.persistor}>
      <IntlProvider>
        <ConnectedRouter history={storeManager.history}>
          <Switch>
            <Route exact path="/" component={DashboardContainer} />
            <Route exact path="/Orders" component={OrdersContainer} />
            <Route exact path="/Settings" component={SettingContainer} />
            <Route exact path="/Language" component={LanaguagesContainer} />
            <Route exact path="/DailyReport" component={DailyReportContainer} />
            <Route exact path="/Login" component={DashboardContainer} />
          </Switch>
        </ConnectedRouter>
      </IntlProvider>
    </PersistGate>
  </Provider>,

  document.getElementById('root')
);

ReportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
