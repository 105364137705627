import React from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Modal } from 'react-bootstrap';

import './css/DeliveryTime.css';

const DeliveryTimePopup = ({ onHide, show, onDone, time }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = value => {
    onDone(parseInt(value.tip));
  };

  return (
    <Modal show={show} onHide={onHide} size="sm" className="tipspopup" centered>
      {/* <Modal.Header closeButton>
        <Modal.Title>Tip Amount</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <h2>Other time</h2>
        <div className="textbox-container">
          <input
            className=""
            placeholder="Time (min.)"
            defaultValue={time ? time : ``}
            type="number"
            autoComplete={false}
            name="tip"
            {...register('tip', { required: true })}
          ></input>
        </div>
        {errors.tip?.type === 'required' && (
          <Alert variant="danger">Please enter the deliver time as min</Alert>
        )}
        <div className="btn-group">
          <button onClick={onHide} className="btn-cancel">
            Cancel
          </button>
          <button onClick={handleSubmit(onSubmit)} className="btn-done">
            Ok
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeliveryTimePopup;
